import { api } from '../api/axios-base'
export const fileUtilsMixin = {
  methods: {
    async uploadFile(fileObj) {
      try {
        const formData = new FormData()
        formData.append('FILES', fileObj.file)
        const response = await api.post('/personas/subir-archivo', 
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        const responseData = await response.data
        // console.log('Respuesta subir archivo:', responseData);
        return responseData
      } catch (error) {
        console.error('Error al intentar subir archivo');
      }
    },
    async postReceiptDoc(docObj) {
      try {
        const response = await api.post('/administracion/crear-documento-comprobante', docObj)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar crear documento comprobante');
      }
    },
    async deleteReceipt(receiptId) {
      try {
        const response = await api.post('/administracion/delete-documento-comprobante', {
          id_documento_comprobante: receiptId
        })
        const responseData = await response.data
        // console.log('Respuesta eliminar documento comprobante:', responseData);
        return responseData
      } catch (error) {
        console.error('Error al intentar eliminar documento comprobante.', error);
      }
    },
    async deleteReceiptPermanently(receiptId) {
      try {
        const response = await api.post('/personas/delete-documento-comprobante-permantente', {
          id_documento_comprobante: receiptId
        })
        const responseData = await response.data
        // console.log('Respuesta eliminar documento comprobante permanentemente:', responseData);
        return responseData
      } catch (error) {
        console.error('Error al intentar eliminar documento comprobante permanentemente.', error);
      }
    },
    downloadFileFromBlob(blob, fileName = 'download.xls') {
      try {
        const blobUrl = window.URL.createObjectURL(blob);
        console.log('blobUrl', blobUrl);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${fileName}`;
        // Append link to the body
        document.body.appendChild(link);

        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

        // Remove link from body
        document.body.removeChild(link);
      } catch (error) {
        console.error(error);
      }
    }
  }
}